import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';

export default () => (
  <DefaultLayout
    pageTitle="Something went wrong"
    pageDescription=""
    bodyClass="bademail"
  >
    <div className="container">
      <section className="center">
        <h1 className="title">Something went wrong.</h1>
        <p>
          Please send us an email at{' '}
          <a href="mailto:contact@andyet.com">contact@andyet.com</a>.
        </p>
      </section>
    </div>
  </DefaultLayout>
);
